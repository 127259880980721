import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactGA from 'react-ga4';

/**
 * 구글 애널리틱스 페이지 통계 측정을 위한 코드
 * 리액트 특성 상 따로 설정이 필요
 */
const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (import.meta.env.VITE_APP_API_GOOGLE_ANALYTICS_ID) {
            ReactGA.initialize(import.meta.env.VITE_APP_API_GOOGLE_ANALYTICS_ID);
            setInitialized(true);
        }
    },[]);

    useEffect(() => {
        if (import.meta.env.VITE_APP_API_GOOGLE_ANALYTICS_ID) {
            if(initialized) {
                // console.log('구글 애널리틱스 운영서버 적용 -- initialized');
                // ReactGA.set({ page: location.pathname });
                // ReactGA.send("pageview");
                // new
                ReactGA.send({ hitType: "pageview", page: location.pathname});
            }
        }
    },[initialized, location]);
}


export default RouteChangeTracker;