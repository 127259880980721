import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'


export const authStore = create(
    devtools(persist(
        (set, get) => ({
            isLogin: null,
            username: '',
            refreshToken: '',
            accessToken: '',
            userAge: '',
            authDate: '',
            
            getIsLogin: () => get().isLogin,
            getUserName: () => get().username,
            getRefreshToken: () => get().refreshToken,
            getAccessToken: () => get().accessToken,
            getUserAge: () => get().userAge,
            getAuthDate: () => get().authDate,
            
            setIsLogin: (boolean) => set({ isLogin: boolean }),
            setUserName: (name) => set({ username: name }),
            setRefreshToken: (token) => set({ refreshToken: token }),
            setAccessToken: (token) => set({ accessToken: token }),
            setUserAge: (age) => set({ userAge: age }),
            setAuthDate: (authDate) => set({ authDate: authDate }),
        }),
        {
            name: 'auth', // name of the item in the storage (must be unique)
        }
    ))
)