import React, { useEffect, useRef } from 'react'
import Img_logo from "../assets/images/logo.png";

export default function Restrict() {
    
    useEffect(() => {
        window.localStorage.clear();
        if(window.location.pathname !== "/login"){
          window.location.href = "/login";
        }
    }, []);


    return (
        <div id="landing">
            <div>
                <img className="logo" src={Img_logo} alt="logo" />
                <h3 className="text-center">개발자 도구를 비활성화 해주세요!</h3>
                <h4 className="text-center">
                    컨텐츠 불법 유포를 방지 및 정상적인 서비스 이용을 위해
                    <br />
                    개발자 도구를 닫은 후 다시 로그인 해주세요.
                </h4>
            </div>
            <div className="bg-black"></div>
        </div>
    );
}
