import * as React from "react";
import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate, BrowserRouter, useNavigate } from "react-router-dom";
import useIsDevToolsOpen from "react-devtools-detector";

import Loading from "./components/Loading";
import loadingStore from "./store/loadingStore";
import {authStore} from "./store/authStore";

import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import RouteChangeTracker from "./utils/RouteCahngeTracker";

import axios from "axios";

// import { axiosInterceptor } from "./services/authHttp";

/** PAGES */
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Landing from "./pages/Landing";

import Login from "./pages/user/Login";
import Join from "./pages/user/Join";
import FindId from "./pages/user/FindId";
import FindPassword from "./pages/user/FindPassword";
import ChangePassword from "./pages/user/ChangePassword";

import Mypage from "./pages/Mypage";
import CoinHistory from "./pages/CoinHistory";

import Payments from "./pages/Payments";
import PaymentsApproval from "./pages/PaymentsApproval";
import PaymentsResult from "./pages/PaymentsResult";
import PaymentsPopup from "./components/PaymentsPopup";
import PaymentsCancel from "./pages/PaymentsCancel";

import ShortFormList from "./components/ShortFormList";
import ShortFormDetail from "./components/ShortFormDetail";
import VideoDetail from "./pages/VideoDetail";
import Release from "./pages/Release";
import Streaming from "./pages/Streaming";

import Community from "./pages/Community";
import NoticeDetail from "./pages/NoticeDetail";
import Support from "./pages/Support";

import TeenagerPolicy from "./pages/TeenagerPolicy";
import CopyRightPolicy from "./pages/CopyRightPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Authentication from "./pages/user/Authentication";
import AuthenticationRequest from "./pages/user/AuthenticationRequest";
import Minor from "./pages/user/Minor";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Notice from "./pages/Notice";
import ApplyActor from "./pages/ApplyActor";
import Maintenance from "./pages/Maintenance";
import Ticket from "./pages/ticket/Ticket";
import NotFound from "./pages/NotFound";
import Restrict from "./pages/Restrict";
import Disabled from "./pages/Disabled";



const LayoutEffect = ({ children }) => {
  const location = useLocation();
  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});


export default function App() {
    const { isLoading } = loadingStore();
    const { isLogin,setIsLogin, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken, accessToken } = authStore();

    // const DEV_TOOL_ENALBLED = false;
    const DEV_TOOL_ENALBLED = import.meta.env.VITE_ENVIRONMENT === "production";

    // 개발자 도구 활성 감지
    const isDevtoolsOpen = useIsDevToolsOpen({
        enabled: DEV_TOOL_ENALBLED,
    });


    useEffect(() => {

        function handleContextMenu(e) {
            e.preventDefault(); // prevents the default right-click menu from appearing
        }
        document.body.addEventListener("contextmenu", handleContextMenu);
        // remove the event listener when the component is unmounted

        return () => {
            document.body.removeEventListener("contextmenu", handleContextMenu);
        };
    }, []);

    if(isDevtoolsOpen) {return (<Restrict />);}


    return (
        <HelmetProvider>
            <CookiesProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <RouteChangeTracker />
                        <LayoutEffect>
                            {isLoading && <Loading />}
                                <Routes>
                                    <Route index path="/" element={<Disabled />} />
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                        </LayoutEffect>
                    </BrowserRouter>

                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </CookiesProvider>
        </HelmetProvider>
    );
}

function RequireAuth({ children }) {
  const { isLogin, username, setIsLogin, setUserName, setUserAge, setAccessToken, setRefreshToken, setAuthDate} = authStore();

  // 같은 브라우저에서 다른 아이디로 로그인 할 시, 새 로그인 정보로 갱신
  const currentAuthStorage = window.localStorage.auth;
  const parsedData = currentAuthStorage && JSON.parse(currentAuthStorage);
  const currentLocalStorageAuthData = parsedData?.state;
  console.log("currentLocalStorageAuthData");
  console.log(currentLocalStorageAuthData);
  
  if (username && username !== currentLocalStorageAuthData?.username) {
    console.log("❌❌기존과 다른 아이디로 로그인❌❌");
    setIsLogin(currentLocalStorageAuthData?.username);
    setUserName(currentLocalStorageAuthData?.username);
    setAccessToken(currentLocalStorageAuthData?.accessToken);
    setRefreshToken(currentLocalStorageAuthData?.refreshToken);
    setUserAge(currentLocalStorageAuthData?.userAge);
    setAuthDate(currentLocalStorageAuthData?.authDate);
    
  }
          
  if (!isLogin) {
    console.log("로그인을 안한 상태입니다. ✅")
    return <Navigate to="/login" state={{access: "denied"}} replace />;
  }

  return children;
}
