import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/reset.css";
import "./styles/bootstrap.css";
import "./styles/global.css";
import "./styles/pages.css";
import "video.js/dist/video-js.css";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";



// React-Devtool 비활성화
if (import.meta.env.VITE_ENVIRONMENT === "production") {
    disableReactDevTools();
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <App />
)
