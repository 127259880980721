import React from 'react'
import Icon_spinner from "../assets/images/icons/progress-spinner.svg";
export default function Loading() {
  return (
    <div style={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 9000
    }}>
        <img className='loading-spinner' src={Icon_spinner} alt="loading" />
    </div>
  )
}
