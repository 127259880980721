import { createAxiosClient } from "./createAxiosClient";
import {authStore} from "../store/authStore";
import useAuth from "../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

const BASE_URL = import.meta.env.VITE_APP_API_SERVER_HOST;
const REFRESH_TOKEN_URL = BASE_URL + '/auth/refresh';

function getCurrentAccessToken() {
    // this is how you access the zustand store outside of React.
    return authStore.getState(state => state).accessToken;
}

function getCurrentRefreshToken() {
    // this is how you access the zustand store outside of React.
    return authStore.getState(state => state).refreshToken;
}

function setAccessTokens(tokens) {
    console.log('set tokens...');
    return authStore.setState({accessToken: tokens});
}

function setRefreshedTokens(tokens) {
    console.log('set tokens...');
    return authStore.setState({refreshToken: tokens});
}

function signout() {
    console.log('sign out...');
    
    authStore.setState({ isLogin: false });
    localStorage.clear();

    window.location.reload();

}

export const client = createAxiosClient({
    options: {
        baseURL: BASE_URL,
        // timeout: 300000,
        // headers: {
        //     'Content-Type': 'application/json',
        // }
    },
    getCurrentAccessToken,
    getCurrentRefreshToken,
    refreshTokenUrl: REFRESH_TOKEN_URL,
    setAccessTokens,
    setRefreshedTokens,
    signout
})