import React from 'react'
import BlurBg from '../assets/images/blur.jpg'
export default function Disabled() {
    return (
        <div style={{ backgroundColor: "black", width: "100%", height: "100vh" }}>
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
            }}>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "520px",
                    width: "100%",
                    padding: "0 16px",
                }}>
                    <section style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        padding: "32px 8px",
                        backgroundColor: "black",
                        borderRadius: 8,
                        fontWeight: 200,
                        textAlign: "center",
                        wordBreak: "keep-all",
                        fontSize:14,
                        color: "#ccc",
                        border: "1px solid #222"
                    }}>
                        <h1 style={{marginBottom: 12, color: "#eee", fontSize: 32}}>NOTICE</h1>
                        <div>
                            플레이조커TV 서비스가 종료되었습니다.
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            그동안 이용해주신 고객분들께 진심으로 감사드립니다.
                        </div>
                        <div style={{
                            marginBottom: 20,
                            fontSize: 20,
                            fontWeight: "300 !important",
                            color: "red",
                        }}>
                            앞으로&nbsp;
                            <span style={{
                                fontWeight: 800
                            }}>
                                룰루플레이TV
                            </span>
                            에서 서비스를 제공합니다.</div>
                        <div>
                            <span style={{fontWeight: 500, color: "#eee"}}>
                            기존 회원 정보는 그대로 이관
                            </span>
                            되어 이용하실 수 있습니다.
                        </div>
                        <div style={{ marginBottom: 32 }}>
                            이용에 불편이 없도록 서비스를 제공하겠습니다.
                        </div>
                        <button onClick={() => {location.href = "https://luluplaytv.com"}} style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "8px 24px",
                            borderRadius: "4px",
                            border: 0
                        }}>룰루플레이TV 서비스 바로가기</button>
                    </section>
                </div>
            </div>
            <img style={{
                disaply: "block",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top"
            }} src={BlurBg} alt="blur bg" />
        </div>
    )
}
