import axios from "axios";

const API_SERVER_URL = import.meta.env.VITE_APP_API_SERVER_HOST;

const api = axios.create({
    baseURL: API_SERVER_URL,
});

// API 요청 URI, 기본 HEADER만 담고 있음
class ApiService {

    /** @GET 배너 리스트 API */
    getBannerList = async () => {
        return await api.get('/banner');
    };

    /** @GET 숏폼 비디오 리스트 API */
    getShortFormList = async (size) => {
        return await api.get(`/product/short?size=${size}&page=0`);
    };

    /** @GET 비디오 리스트 API */
    getVodList = async (size, page) => {
        return await api.get(`/product/vod/active?size=${size}&page=${page}`);
    };

    /** @GET 비디오 카테고리 전체 조회 API */
    getCategoryListData = async () => {
        return await api.get(`/product/category`,{
            headers: { "Content-type": "application/json", },
        });
    };

    /** @GET 비디오 카테고리 단건 조회 API */
    getCategoryData = async (categoryId, size) => {
        return await api.get(`/product/category/${categoryId}?size=${size}&page=0`, {
            headers: { "Content-type": "application/json", },
        });
    };

}

export default new ApiService();
