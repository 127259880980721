import axios from "axios";
import { client } from "./axiosClient";

class AuthApiService {


    /** @GET 숏폼 비디오 리스트 API */
    getShortFormListWithPlayUrl = async (size) => {
        return await client.get(`/product/short?size=${size}&page=0`, {
            headers: { "Content-type": "application/json" },
            withCredentials: true,
        });
    };

    /** @GET 찜 비디오 리스트 API */
    getWishVideoList = async () => {
        return await client.get('/product/vod/take');
    };

    /** @POST or @DELETE 찜 비디오 리스트 API */
    modifyWishItem = async (listId, method) => {
        if (method === "POST") {
            return await client.post(`/product/vod/take/${listId}`,{
                headers: { "Content-type": "application/json", }
            });
        } else if (method === "DELETE") {
            return await client.delete(`/product/vod/take/${listId}`, {
                headers: { "Content-type": "application/json", },
                data: { listId: listId }
            });
        }
    };

    /** @GET 출시예정 비디오 리스트 API */
    getReleaseVodList = async () => {
        return await client.get('/product/vod/soon');
    };
    
    /** @POST vod 주문 API */
    postOrderVod = async (uuid) => {
        return await client.post(`/order/vod/${uuid}`, {
            headers: { "Content-type": "application/json", },
        });
    };

    /** @GET 선택한 비디오가 시청 가능한지 확인 API */
    getVideoCanWatchable = async (uuid) => {
        return await client.get(`/product/vod/play/${uuid}`,{
            headers: { "Content-type": "application/json", },
            withCredentials: true,
        });
    };

    /** @GET 비디오 단건 조회 API */
    getVideoData = async (uuid) => {
        return await client.get(`/product/vod/${uuid}`,{
            headers: { "Content-type": "application/json", },
        });
    };

    /** @GET 비디오 카테고리 단건 조회 API */
    getCategoryData = async (categoryId, size, pageParam) => {
        return await client.get(`/product/category/${categoryId}?size=${size}&page=${pageParam}`, {
            headers: { "Content-type": "application/json", },
        });
    };

    /** @GET 내가 시청 가능한 비디오 리스트 API*/
    getMyVideoList = async () => {
        return await client.get(`/order/vod/active`);
    };

    /** @GET 코인 사용 내역 조회 API */
    getUsedCoin = async () => {
        return await client.get(`/coin/used`);
    };

    /** @GET 코인 충전 내역 조회 API */
    getOrderCoin = async () => {
        return await client.get(`/order/coin`);
    };

    /** @GET [코인] 결제 결과 조회 API */
    getOrderResult = async (uuid) => {
        return await client.get(`/order/coin/${uuid}`);
    };

    /** @GET [티켓] 결제 결과 조회 API */
    getTicketOrderResult = async (uuid) => {
        return await client.get(`/order/ticket/result/${uuid}`);
    };

    /** @GET 결제 상품 조회 API */
    getProductCoin = async () => {
        return await client.get(`/product/coin`);
    };

    /** @POST 주문서 생성 API */
    postOrderCoin = async (id) => {
        return await client.post(`/order/coin/${id}`, {
            headers: { "Content-type": "application/json", },
        });
    };

    /** @POST 회원 탈퇴 API */
    postWithdrawal = async (data) => {
        return await client.post(`/auth/unregister`, data, {
            headers: { "Content-type": "application/json", },
        });
    };

    /** @POST 비밀번호 변경 API */
    postChangePassword = async (userData) => {
        return await client.post(`/auth/password`, userData, {
            headers: { "Content-type": "application/json", },
        });
    };

    /** @GET 내 코인 불러오기 API */
    getMyCoin = async () => {
        return await client.get(`/coin`, {
            headers: { "Content-type": "application/json", },
        });
    }

    /** @POST 배우 지원 API */
    postApplyActor = async (applyData) => {
        return await client.post(`/request/actor`, applyData, {
            headers: { "Content-Type": "multipart/form-data", },
        });
    };

    /** @GET 공지사항 리스트 [전체] 불러오기 API */
    getNoticeList = async (size, page) => {
        return await client.get(`/notice?size=${size}&page=${page}`);
    }

    /** @GET 공지사항 리스트 [단건] 불러오기 API */
    getNoticeData = async (id) => {
        return await client.get(`/notice/${id}`);
    }

    /** @GET 티켓 리스트 [전체] 불러오기 API */
    getTicketList = async () => {
        return await client.get(`/product/ticket`);
    }

    /** @GET 티켓 데이터 [단건] 불러오기 API */
    getTicketData = async (id) => {
        return await client.get(`/product/${id}`);
    }


    /** @GET 티켓 구매내역 조회 API */
    getOrderTicketHistory = async (id) => {
        return await client.get(`/order/ticket/${id}`);
    }

    /** @GET 티켓 구매를 위한 유저 데이터 불러오기 API */
    getUserData = async () => {
        return await client.get(`/order/ticket/auth`);
    }
    
    /** @GET 티켓 주문서 생성 API */
    postOrderTicket = async (id, data) => {
        return await client.post(`/order/ticket/${id}`, data,{
            headers: { "Content-type": "application/json" },
        });
    }
    /** @POST 티켓 취소 API */
    postTicketRefund = async (uuid) => {
        // return await client.post(`/order/ticket/refund/${uuid}`); // 웰컴페이먼츠
        return await client.post(`/order/ticket/refund/v2/${uuid}`); // 페이플
    }

    /** @POST 티켓 이메일 재전송(예매자 정보도 수정) API */
    postResendTicket = async (uuid, data) => {
        return await client.post(`/order/ticket/resend/${uuid}`, data,{
            headers: { "Content-type": "application/json" },
        });
    }


    //refreshToken 이용하여 accessToken 요청
    reqAccessToken = async (data) => {
        return await client.post('/auth/refresh', data,
            { headers: { 'Content-Type': 'application/json' } }
        );
    }
}

export default new AuthApiService();



